:root {
  --bg-gradient-1: linear-gradient(0deg,
      rgba(27, 17, 77, 1) -8.35%,
      rgba(29, 22, 87, 1) -5.1%,
      rgba(51, 65, 174, 1) 28.53%,
      rgba(59, 82, 208, 1) 43.72%,
      rgba(53, 100, 213, 1) 51.31%,
      rgba(37, 146, 225, 1) 67.59%,
      rgba(12, 219, 245, 1) 89.28%,
      rgba(0, 255, 255, 1) 100.13%);
  --grid-colour-1:rgba(0, 255, 255, 1);
  --bg-gradient-2: linear-gradient(0deg,
      #ffd319 -8.35%,
      #ffb21c -5.1%,
      #ff901f 28.53%,
      #ff5d4a 43.72%,
      #ff2975 51.31%,
      #f222ff 67.59%,
      #bf20ff 89.28%,
      #8c1eff 100.13%);
  --grid-colour-2:#8c1eff;
  --bg-gradient-3: linear-gradient(0deg,
      #12c2e9 -8.35%,
      #3faeea -5.1%,
      #6b9aeb 28.53%,
      #9886ec 43.72%,
      #ae7ced 51.31%,
      #c471ed 67.59%,
      #dd60a3 89.28%,
      #f64f59 100.13%);
  --grid-colour-3:#12c2e9;
  --bg-gradient-4: linear-gradient(0deg,
      #1F4037 -8.35%,
      #2F574A -5.1%,
      #3E6D5C 28.53%,
      #5C9980 43.72%,
      #6CB092 51.31%,
      #7BC6A4 67.59%,
      #8ADCB6 89.28%,
      #99F2C8 100.13%);
  --grid-colour-4:#99F2C8;
  --bg-gradient-5: linear-gradient(0deg,
      #A7EDFA -8.35%,
      #9AEAF4 -5.1%,
      #8CE6EE 28.53%,
      #BED6E7 43.72%,
      #D7CEE3 51.31%,
      #F0C5DF 67.59%,
      #DC99BA 89.28%,
      #C86D95 100.13%);
  --grid-colour-5: #C86D95;
  --bg-gradient-6: linear-gradient(0deg,
      #E602BC -8.35%,
      #AE20C5 -5.1%,
      #753ECD 28.53%,
      #2060C6 43.72%,
      #036BC3 51.31%,
      #023D6F 67.59%,
      #011F38 89.28%,
      #000000 100.13%);
  --grid-colour-6: #000000;
  --canvas-bg: var(--bg-gradient-1);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: linear-gradient(
    0deg,
    rgba(27,17,77,1) -8.35%,
    rgba(29,22,87,1) -5.1%,
    rgba(51,65,174,1) 28.53%,
    rgba(59,82,208,1) 43.72%,
    rgba(53,100,213,1) 51.31%,
    rgba(37,146,225,1) 67.59%,
    rgba(12,219,245,1) 89.28%,
    rgba(0,255,255,1) 100.13%
  );
  background: var(--bg-gradient-1);
  transform: rotate(-180deg);
  transition: opacity 3s;
  opacity: 1;
}

.loading--complete {
  opacity: 0;
}

 
.loading svg {
  display: block;
  margin: auto; 
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;  
  shape-rendering: auto;
}

.loading path {
  fill: none;
  stroke:  #ff0cb8;
  transform:scale(0.8);
  transform-origin:50px 50px;
}



#play-icon {
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  width: 120px;
  height: 120px;
  fill: white;
  z-index: 1;
  opacity: 1;
  transition: opacity 1s;
  cursor: pointer;
}

#play-icon.fade-out {
  opacity: 0;
}

#defaultCanvas0 {
  background: var(--canvas-bg);
}